import React from 'react';
import { Helmet } from 'react-helmet';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import FooterCommon from 'sections/ScreverOcxProgram/FooterCommon';

import 'sections/ScreverOcxProgram/common.scss';
import ParticipantsList from 'sections/ScreverOcxProgram/ParticipantsList/index';

const PAGES = [
  {
    title: 'Program',
    url: '/events/screver-ocx-program/program',
  },
  {
    title: '✌🏻Feedback',
    url: '/events/screver-ocx-program/feedback',
  },
  {
    title: 'Casino instructions',
    url: '/events/screver-ocx-program/casino-instructions',
  },
];

const ParticipantsPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Screver OCX Event | Participants"
      >
        <meta
          name="description"
          content="Discover the list of participants for the upcoming Screver OCX event. See who will be joining us."
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className="scr-ocx-program">
        <HeaderCommon title="Participants" />

        <main>
          <div className="ocx-prog-wrap">
            <ParticipantsList />
          </div>
        </main>

        <FooterCommon linksToPages={PAGES} />
      </div>
    </>
  );
};

export default ParticipantsPage;
